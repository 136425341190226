<template>
	<div class="m71 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.push({ name: 'Alram' })">
					<div class="top-iconClose">
						<img
							:src="require('@/assets/img/ic_notifications.svg')"
							alt="알림"
						/>
					</div>
				</a>
				<p>더보기</p>
			</div>
		</div>
		<div class="top-bg">
			<!-- 회원정보 더보기 -->
			<div class="personal-info">
				<div class="box-infotxt more">
					<p>
						DID ( {{ memberInfo.nm }} )
						<span>DID:{{ memberInfo.kpDid }}</span>
					</p>
					<img :src="require('@/assets/img/img_m_qr.svg')" alt="카드이미지" />
				</div>
				<div class="box-shadow"></div>
			</div>
			<!-- 회원정보 더보기 끝-->
		</div>
		<!-- End of Heaer Section -->

		<!-- 더보기 메뉴 -->
		<div class="bottom-bg more">
			<div class="more-menu">
				<ul class="bottom_last">
					<li>
						<a @click="clickListener(0)">
							<p>설정</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a @click="clickListener(1)">
							<p>공지사항</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a @click="clickListener(2)">
							<p>자주하는 질문</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a @click="clickListener(3)">
							<p>이용안내</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a @click="clickListener(4)">
							<p>이용약관</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li class="version">
						<p>앱버전</p>
						<span>{{ appVersion }}</span>
					</li>
					<li>
						<a @click="clickListener(5)">
							<p>회원탈퇴</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
				</ul>
			</div>
			<!-- 더보기 메뉴 -->

			<Footer :id="3" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Footer from '@/components/Footer';
import { KPIC_dispatchAppVersion } from '@/util/appBridge';

export default {
	name: 'More',

	components: {
		Footer,
	},

	computed: {
		...mapGetters(['memberInfo']),
	},

	data() {
		return {
			appVersion: '',
		};
	},

	created() {
		console.debug(
			'doAppVersionCallback({RESULT: "OK", DATA: {appVersion: "1.0"}})',
		);
		KPIC_dispatchAppVersion();

		window.doAppVersionCallback = res => {
			window.doAppVersionCallback = null;
			console.debug('doAppVersionCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					// this.DID = res.DATA.DID;
					this.appVersion = res.DATA.appVersion;
				}
			}
		};
	},

	methods: {
		clickListener(index) {
			if (index === 0) {
				this.$router.push({ name: 'Settings' });
			} else if (index === 1) {
				this.$router.push({ name: 'Notice' });
			} else if (index === 2) {
				this.$router.push({ name: 'QnA' });
			} else if (index === 3) {
				this.$router.push({ name: 'Usage' });
			} else if (index === 4) {
				this.$router.push({ name: 'TosList' });
			} else if (index === 5) {
				this.$router.push({ name: 'Withdrawal' });
			}
		},
	},
};
</script>
