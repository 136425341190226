<template>
	<div class="m72 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>설정</p>
			</div>
		</div>
		<!-- End of Header Section -->

		<div class="more-settings top-4">
			<div class="more-menu">
				<ul>
					<li>
						<a @click="passCodeReset">
							<p>비밀번호 재설정</p>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<li>
						<p>생체인증 활성화</p>
						<div class="custom-switch">
							<input
								type="checkbox"
								class="setcustom-control-input"
								v-model="fingerUsage"
								@change="changeListener('fingerUsage', $event)"
								id="switch1"
							/>
							<label class="setcustom-control-label" for="switch1"></label>
						</div>
					</li>
					<!-- <li>
						<p>얼굴인식 설정</p>
						<div class="custom-switch">
							<input
								type="checkbox"
								class="setcustom-control-input"
								v-model="faceUsage"
								@change="changeListener('faceUsage', $event)"
								id="switch2"
							/>
							<label class="setcustom-control-label" for="switch2"></label>
						</div>
					</li> -->
					<li>
						<p>알림 설정</p>
						<div class="custom-switch">
							<input
								type="checkbox"
								v-model="alarmUsage"
								@change="changeListener('alarmUsage', $event)"
								class="setcustom-control-input"
								id="switch3"
							/>
							<label class="setcustom-control-label" for="switch3"></label>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- End of More Setting Html Code -->
		<div v-show="showAlert" class="setting-alert">
			<!-- 지문인증 설정 -->
			<p>{{ alertMsg }}</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	KPIC_dispatchRegPassCode,
	KPIC_dispatchUpdateAuthType,
	KPIC_dispatchGetAuthType,
} from '@/util/appBridge';
import { KPICApi } from '@/api';

export default {
	name: 'Settings',

	components: {
		//
	},

	computed: {
		...mapGetters(['memberInfo']),
	},

	data() {
		return {
			isCodeModalShow: false,
			selectedNavCode: 0,

			fingerUsage: false,
			faceUsage: false,
			alarmUsage: false,

			showAlert: false,
			alertMsg: '',
		};
	},

	created() {
		window.dispatchGetAuthTypeCallback = res => {
			window.dispatchGetAuthTypeCallback = null;
			console.debug('dispatchGetAuthTypeCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					this.fingerUsage = res.DATA.fingerPrintUsage;
				}
			}
		};
		KPIC_dispatchGetAuthType();

		this.alarmUsage =
			this.memberInfo.ntcnUseatCd.length > 0
				? this.memberInfo.ntcnUseatCd === 'Y'
					? true
					: false
				: false;
	},

	methods: {
		codePassModalCloseListener() {
			this.isCodeModalShow = false;
		},

		navClickListener(code) {
			this.selectedNavCode = code;
		},

		passCodeReset() {
			window.dispatchRegPassCodeCallback = res => {
				window.dispatchRegPassCodeCallback = null;
				console.debug('dispatRegPassCodeCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						alert('비밀번호 재설정 되었습니다.');
					}
				} else {
					alert('비밀번호 재설정 되지 않았습니다.');
				}
			};
			KPIC_dispatchRegPassCode();
		},

		changeListener(type, event) {
			if (event.target.checked === true) {
				if (type === 'fingerUsage') {
					window.dispatchUpdateAuthTypeCallback = res => {
						window.dispatchUpdateAuthTypeCallback = null;
						console.debug('dispatUpdateAuthTypeCallback', res);
						if (res && res.RESULT) {
							if (res.RESULT === 'OK') {
								this.alertMsg = '지문인증이 설정되었습니다.';
								this.showAlert = true;
							}
						}
					};
					KPIC_dispatchUpdateAuthType(event.target.checked);
				} else if (type === 'faceUsage') {
					this.alertMsg = '얼굴인식이 설정되었습니다.';
				} else if (type === 'alarmUsage') {
					KPICApi.saveMemberAlram(
						this.memberInfo.mberId,
						event.target.checked ? 'Y' : 'N',
					).then(({ data }) => {
						if (data.RESULT_CD === '1') {
							this.alertMsg = '알림이 설정되었습니다.';
							this.showAlert = true;
						}
					});
				}
			} else {
				if (type === 'fingerUsage') {
					window.dispatchUpdateAuthTypeCallback = res => {
						window.dispatchUpdateAuthTypeCallback = null;
						console.debug('dispatUpdateAuthTypeCallback', res);
						if (res && res.RESULT) {
							if (res.RESULT === 'OK') {
								this.alertMsg = '지문인증이 해제되었습니다.';
								this.showAlert = true;
							}
						}
					};
					KPIC_dispatchUpdateAuthType(event.target.checked);
				} else if (type === 'faceUsage') {
					this.alertMsg = '얼굴인식이 해제되었습니다.';
				} else if (type === 'alarmUsage') {
					KPICApi.saveMemberAlram(
						this.memberInfo.mberId,
						event.target.checked ? 'Y' : 'N',
					).then(({ data }) => {
						if (data.RESULT_CD === '1') {
							this.alertMsg = '알림이 해제되었습니다.';
							this.showAlert = true;
						}
					});
				}
			}

			setTimeout(() => {
				this.showAlert = false;
			}, 1500);
		},
	},
};
</script>
