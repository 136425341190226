<template>
	<div class="m76 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>공지사항</p>
			</div>
		</div>
		<!-- End of Header Section -->

		<div class="more-lists top-4">
			<ul>
				<!-- Loof Accordion item -->
				<li v-for="(item, index) in listData" :key="index" class="notice-lists">
					<div class="notice-icon">
						<div class="notice-date">
							<div class="notice-tit">
								<span>{{ item.noticetyNm }}</span>
								<p class="item-title">
									{{ item.sj }}
									<span class="accIcon"></span>
								</p>
							</div>
							<i>{{ item.writngDe8Mk }}</i>
						</div>
						<div @click="openDetail(item)" class="item-txt active">
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
								class="noticeBtn"
							>
								<path
									class="st0"
									d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
								/>
							</svg>
						</div>
					</div>
					<div
						class="notice-details"
						:style="{ display: item.detailOpenYN ? 'block' : 'none' }"
					>
						<p>{{ item.cn }}</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { KPICApi } from '@/api';

export default {
	name: 'Notice',

	data() {
		return {
			listData: [],
		};
	},

	methods: {
		openDetail(item) {
			console.debug('aaaa');
			item.detailOpenYN = !item.detailOpenYN;
			console.debug(item.detailOpenYN);
		},
	},

	created() {
		KPICApi.fetchNoticeList()
			.then(({ data }) => {
				console.debug('Notice res', data);
				if (data.RESULT_CD === '1') {
					const tempList = data.apiNoticesList.map(item => {
						item.detailOpenYN = false;
						item.detailData = null;
						return item;
					});
					this.listData = tempList;
				} else {
					alert(data.RESULT_MSSAGE);
				}
			})
			.catch(err => {
				alert(err);
			});
	},
};
</script>
