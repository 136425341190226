<template>
	<div class="m78 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>이용안내</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="info-use top-4">
			<div class="sertifi-input">
				<select>
					<option value="KP-APP-D-SC-6008.html" selected>개인회원 가입</option>
					<option value="KP-APP-D-SC-6008_2.html">법인회원 가입</option>
					<option value="KP-APP-D-SC-6008_3.html">우정서비스 앱 로그인</option>
					<option value="KP-APP-D-SC-6008_4.html">우정서비스 웹 로그인</option>
					<option value="KP-APP-D-SC-6008_5.html">이용내역 조회</option>
					<option value="KP-APP-D-SC-6008_6.html">마이데이터</option>
				</select>
				<svg
					version="1.1"
					id="레이어_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
					/>
				</svg>
			</div>
			<!-- 이용안내 슬라이드 -->
			<div class="info-slides">
				<ul class="box-slides">
					<li class="items">
						<img :src="require('@/assets/img/img_m_detail.png')" />
						<span>01. <i> 개인회원 가입</i></span>
					</li>
					<li class="items">
						<img :src="require('@/assets/img/img_m_detail.png')" />
						<span>02. <i> 개인회원 가입</i></span>
					</li>
					<li class="items">
						<img :src="require('@/assets/img/img_m_detail.png')" />
						<span>02. <i> 개인회원 가입</i></span>
					</li>
					<li class="items">
						<img :src="require('@/assets/img/img_m_detail.png')" />
						<span>02. <i> 개인회원 가입</i></span>
					</li>
					<li class="items">
						<img :src="require('@/assets/img/img_m_detail.png')" />
						<span>02. <i> 개인회원 가입</i></span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Usage',
};
</script>
