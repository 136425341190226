<template>
	<div class="m80 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>회원탈퇴</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="box-leave top-4">
			<p>
				우체국 앱 서비스(이하"서비스")탈퇴를 진행 하기 전 아래 내용을
				확인해주세요.
			</p>
			<div class="leave-txt">
				<ul>
					<li>
						<i>서비스 이용을 위해 휴대전화에 저장된 관련 정보가 삭제됩니다.</i>
					</li>
					<li>
						<i>이용했던 서비스에 불편이 생길 수 있습니다.</i>
					</li>
					<li>
						<i>서비스를 사용하려면 서비스 가입부터 다시 진행해야 합니다.</i>
					</li>
				</ul>
				<div class="box-shadow"></div>
			</div>
		</div>

		<div class="custom-control">
			<input
				type="checkbox"
				class="custom-control-input"
				id="leaveCheck"
				v-model="checked"
			/>
			<label class="custom-control-label" for="leaveCheck">
				<p>상기 내용을 동의합니다.</p>
			</label>
		</div>

		<div class="btn-app">
			<button type="button" class="btn-select" @click="confirm">확인</button>
		</div>
	</div>
</template>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
.custom-control {
	display: flex;
	align-items: center;
	min-height: 1.5rem;
}
</style>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { KPICApi } from '@/api';
import {
	KPIC_dispatchDeleteDID,
	KPIC_dispatchRequestAuth,
} from '@/util/appBridge';

export default {
	name: 'Withdrawal',

	computed: {
		...mapGetters(['memberInfo']),
	},

	data() {
		return {
			checked: false,
		};
	},

	methods: {
		...mapMutations([
			'saveRegisterProgressState',
			'saveRegisterStepState',
			'updateLoadingProgressState',
		]),

		confirm() {
			// 체크 동의를 확인한다.
			if (this.checked) {
				this.updateLoadingProgressState(true);

				window.dispatchRequestAuthCallback = res => {
					window.dispatchRequestAuthCallback = null;
					console.debug('dispatchRequestAuthCallback', res);
					if (res && res.RESULT) {
						if (res.RESULT === 'OK') {
							KPICApi.deactiveMemberAccount(this.memberInfo.mberId)
								.then(({ data }) => {
									console.debug('fetchFaqList res', data);
									if (data.RESULT_CD === '1') {
										window.dispatchDeleteDIDCallback = res => {
											window.dispatchDeleteDIDCallback = null;
											this.updateLoadingProgressState(false);
											console.debug('dispatchDeleteDIDCallback', res);
											if (res && res.RESULT) {
												if (res.RESULT === 'OK') {
													this.$router.replace({ path: '/' });
												}
											}
										};
										KPIC_dispatchDeleteDID();
									} else {
										this.updateLoadingProgressState(false);
										alert(data.RESULT_MSSAGE);
									}
								})
								// eslint-disable-next-line no-unused-vars
								.catch(err => {
									this.updateLoadingProgressState(false);
								});
						} else {
							this.updateLoadingProgressState(false);
						}
					} else {
						this.updateLoadingProgressState(false);
					}
				};

				KPIC_dispatchRequestAuth();
			} else {
				alert('동의를 선택해 주세요.');
			}
		},
	},
};
</script>
