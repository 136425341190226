<template>
	<div class="m77 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<div class="top-iconBack" @click="$router.go(-1)">
					<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
				</div>
				<p>자주하는 질문</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="qna-container top-4">
			<!-- 회원가입 Q&A -->
			<ul v-for="(item, index) in listData" :key="index" class="qna-join">
				<p>{{ item.ctgrytyNm }}</p>
				<li v-for="(item, index) in item.list" :key="index">
					<div class="qna-tit">
						<span>{{ item.sj }}</span>
						<div @click="openDetail(item)" class="qna-icon">
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
								class="noticeBtn"
							>
								<path
									class="st0"
									d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
								/>
							</svg>
						</div>
					</div>
					<div
						class="qna-txt"
						:style="{ display: item.detailOpenYN ? 'block' : 'none' }"
					>
						<i>
							{{ item.cn }}
						</i>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { KPICApi } from '@/api';

export default {
	name: 'Notice',

	data() {
		return {
			listData: [],
		};
	},

	methods: {
		openDetail(item) {
			console.debug('aaaa');
			item.detailOpenYN = !item.detailOpenYN;
			console.debug(item.detailOpenYN);
		},
	},

	created() {
		KPICApi.fetchFaqList()
			.then(({ data }) => {
				console.debug('fetchFaqList res', data);
				if (data.RESULT_CD === '1') {
					const cateList = [];
					const tempList = data.mainqestnList.map(item => {
						const temp = cateList.find(cate => {
							return cate.ctgrytyCd === item.ctgrytyCd;
						});

						if (!temp) {
							cateList.push(item);
						}
						item.detailOpenYN = false;
						return item;
					});

					const tempList1 = cateList.map(cate => {
						const grouping = tempList.filter(data => {
							return data.ctgrytyCd === cate.ctgrytyCd;
						});
						return { ctgrytyNm: cate.ctgrytyNm, list: grouping };
					});
					console.debug(tempList1);
					this.listData = tempList1;
				} else {
					alert(data.RESULT_MSSAGE);
				}
			})
			.catch(err => {
				console.debug('fetchFaqList', err);
				alert('네트워크 장애');
			});
	},
};
</script>
